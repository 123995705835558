.rct-tree-items-container .col {
  padding: 0 4px 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.header-row {
  display: flex;
  align-items: center;
  height: 30px;
  border-top: 1px solid #b2b2b2;
}
.header-row .col-header {
  border-left: 1px solid #b2b2b2;
  padding: 0 4px 0 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  position: relative;
}
.header-row .col-header:last-child {
  border-right: 1px solid #b2b2b2;
}
.header-row .col {
  padding: 0 4px 0 10px;
}
.rct-tree-items-container .row {
  display: flex;
  height: 30px;
  align-items: center;
  width: 100% !important;
  box-sizing: border-box;
  box-shadow: 1px 1px #b2b2b2;
}
// .rct-tree-items-container div :has(.rct-tree-item-arrow) {
//   width: 5px;
// }
.rct-tree-item-arrow {
  // margin-left: 3px;
  position: absolute;
}
/* .rct-tree-items-container li {
    border-top: 1px solid #b2b2b2;
  } */

.rct-tree-items-container ul[role="group"] {
  padding-left: 10px;
}
.replaceTemplate-list div[data-rct-tree="tree-2"] {
  border-bottom: 1px solid #b2b2b2;
}
.react-split__sash--horizontal {
  background-color: #b2b2b2 !important;
}
#fluent-default-layer-host {
  z-index: unset !important;
  position: relative !important;
}

.rct-tree-items-container li[aria-selected="true"] > div:first-child {
  background-color: #a8d3f3;
}

.treeToc {
  height: 100%;
}
.treeToc .tabtoc {
  margin-left: 10px;
}
.treeToc ul,
.treeToc li {
  list-style-type: none;
}
.treeToc .selected,
.treeToc .selected input {
  background-color: #a8d3f3;
}

.dnd-sortable-tree_simple_clone .row {
  display: flex;
  width: 100%;
}

.treeToc .row {
  display: flex;
  border-left: 1px solid #b2b2b2;
  border-top: 1px solid #b2b2b2;
  height: 30px;
  align-items: center;
}
.treeToc .rct-tree-root {
  border-right: 1px solid #b2b2b2;
}

.treeToc .col {
  border-right: 1px solid #b2b2b2;
  height: 100%;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.treeToc .border-top {
  border-top: 1px solid #b2b2b2;
}
.treeToc .border-bottom {
  border-bottom: 1px solid #b2b2b2;
}

.treeToc .border-right {
  border-right: 1px solid #b2b2b2;
}

.treeToc .border-left {
  border-left: 1px solid #b2b2b2;
}

.rct-tree-root {
  padding: 0 !important;
}
.rct-tree-root [role="tree"] {
  min-height: 0 !important;
}
.treeToc li[aria-selected="true"] > div:first-child {
  background-color: #a8d3f3;
}

.treeToc ul[role="group"] {
  padding-left: 10px;
}
.resizer:hover {
  background: #b2b2b2;
}
.resizer {
  background: transparent;
  width: 2px;
  cursor: col-resize;
  height: 100%;
  right: 0;
  position: absolute;
}
.resizer.left {
  left: 0;
}
