.table-settings {
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  padding-bottom: 10px;
  margin-top: 10px;
  .table-header {
    border-bottom: 1px solid #b2b2b2;
    margin-bottom: 8px;
  }
}
