.MDPreviewPublish {
  .thumnail-img canvas {
    max-width: 130px;
  }
  .file-list {
    .item {
      text-align: center;
      img {
        width: 100px;
        margin: auto;
        max-height: 200px;
        font-size: 66px;
        display: block;
        border: 1px solid rgb(178, 178, 178);
      }
    }
  }
  .active {
    border: 1px solid #5f91ff !important;
    background: #a6cbff;;
  }
  .header {
    font-size: 11px;
    text-align: center;
    border-bottom: 1px solid #b2b2b2;
  }
}
