.search-pn {
  margin: 0 8px;
  height: calc(100% - 48px);
  .header {
    height: 40px;
    border-bottom: 1px solid #d9d9d9;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .ant-tabs-nav {
    margin: 0 0 8px 0;
  }
  .search-result-checkbox span:last-child {
    display: flex;
  }
  .grid-cell {
    border: #d9d9d9 solid;
    border-width: 0.5px 0 0;
    display: flex;
    align-items: center;
  }
  .grid-cell:hover {
    background-color: #f5f5f5;
  }
  .ReactVirtualized__Grid {
    border: 1px #d9d9d9 solid;
    border-radius: 3px;
  }
  // .ant-tabs-content-top,
  // .ant-tabs-tabpane,
  // .ant-tabs-top,
  // .ant-tabs-content-holder {
  //   height: 100%;
  // }
}
