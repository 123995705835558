.left-pn {
  height: 100%;
  .rc-tabs-nav-list {
    border: 1px solid #d9d9d9;
  }
  .rc-tabs-tab {
    justify-content: center;
  }
}

