html,
body,
#root {
  height: calc(100% - 6px);
  width: 100%;
  font-size: 12px !important;
  user-select: none;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #d1d1d1;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #d1d1d1;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #a5acb4;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.confirm-dialog,
.confirm-dialog-mask {
  z-index: 10000 !important;
  width: unset !important;
}
.loadding-app {
  position: absolute;
  z-index: 110000;
  margin: auto;
  height: 100%;
  width: 100%;
  background: #00000026;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadding-app img {
  width: 50px;
  height: 50px;
}
ul.popover {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.popover li {
  padding: 5px;
  cursor: pointer;
}
ul.popover li:hover {
  background: #1677ff;
  color: white;
  border-radius: 3px;
}
.rct-tree-root ul {
  list-style: none;
}
